<template>
  <div class="BigBox">
    开发中 敬请期待
    <button @click="Back">返回</button>
  </div>
</template>

<script>
export default {
  methods:{
    Back(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.BigBox{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

</style>